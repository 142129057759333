<template>
  <div class="flex">
    <button
      type="button"
      aria-label="Pass the text in English"
      id="ENLang"
      @click="switchLocale('en')"
      class="
        shadow-md
        w-8
        h-8
        flex
        justify-center
        items-center
        px-3
        py-1
        rounded
        m-1
        border
        bg-gray-900
        border-white
        text-white
        hover:bg-blue-500 hover:text-black
      "
    >
      EN
    </button>
    <button
      type="button"
      aria-label="Passer le texte en Français"
      id="FRLang"
      @click="switchLocale('fr')"
      class="
        shadow-md
        w-8
        h-8
        flex
        justify-center
        items-center
        px-3
        py-1
        rounded
        m-1
        border
        bg-gray-900
        border-white
        text-white
        hover:bg-blue-500 hover:text-black
      "
    >
      FR
    </button>
  </div>
</template>

<script>
export default {
  name: "LocalSwitcher",
  methods: {
    switchLocale(locale) {
      localStorage.setItem("lang", locale);
      this.$i18n.locale = localStorage.getItem("lang");
      if (locale == "en") {
        document.title = "Hyvirea - The VR therapy application";
      } else {
        document.title = "Hyvirea - L'application VR thérapeuthique";
      }
    },
    launchLangPref() {
      const langLS = localStorage.getItem("lang");
      if (
        langLS == "fr" ||
        navigator.language == "fr-FR" ||
        navigator.language == "fr" ||
        navigator.language == "fr-CA" ||
        navigator.language == "fr-CH"
      ) {
        this.switchLocale("fr");
      } else {
        this.switchLocale("en");
      }
      if (langLS == "en") {
        this.switchLocale("en");
      }
    },
  },
  mounted() {
    this.launchLangPref();
  },
};
</script>
<style lang="scss">
#FRLang {
  &:active {
    background-color: rgb(0, 132, 255);
  }
}
#ENLang {
  &:active {
    background-color: rgb(160, 40, 40);
  }
}
</style>
