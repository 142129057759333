export default {
  "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application VR au but thérapeuthique"])},
  "section1": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire voyager les personnes dans le monde incroyable de la réalité virtuelle pour accompagner le patient pendant des opérations médicales. La réalité virtuelle, une aide thérapeutique ? La VR (réalité virtuelle) ne se limite pas au divertissement. Le champ des possibles de la VR est énorme et <strong>s’applique dès à présent dans le secteur médical.</strong> En effet, cela peut être un soutien thérapeutique non négligeable pour accompagner le patient. Qu’il s’agisse d’anesthésie, de traitement ou de diagnostic, la réalité virtuelle s'est avérée<strong> très efficace pour calmer le patient pendant une opération.</strong > Cette technologie, dans le secteur de la santé à fait ses preuves et les possibilités changeront la médecine de demain. <strong>Hyvirea</strong> est destinée aux interventions chirurgicales en anesthésie loco-régionale, elle permet de limiter le stress du patient lors de l’intervention."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour cela, le patient est immergé dans un <strong>monde virtuel relaxant</strong>. De cette manière, lors de l’intervention, un infirmier anesthésiste (IADE) suivra l’avancée du patient dans l’application <strong>Hyvirea</strong>. De plus, il peut le guider et paramétrer l’application selon les désirs du patient (envies, peurs...). <strong>Hyvirea</strong> est une application simple, le patient est observateur de l’environnement. <strong>Il se laisse transporter dans un monde rassurant et complètement différent du bloc opératoire dans lequel il se situe physiquement.</strong>"])}
  },
  "section2": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Le projet HYVIREA (Hypnose Virtual Reality)</strong> a été initié pendant la période de confinement suite à la pandémie de la COVID-19 et réalisé par le <a href=\"https://www.vrtig0.fr\" target=\"blank\" rel=\"noopener\" class=\"underline text-black visited:text-purple-600 hover-text-blue\" ><strong>studio Vr'tig.0.</strong></a> En effet, depuis le début du confinement les hôpitaux français ont accueilli un grand nombre de patients atteints de ce virus. Se sont donc vu utiliser une quantité conséquente de curare dans les services de réanimation afin de les plonger dans un coma artificiel. Le problème étant que cette molécule est aussi utilisée en bloc opératoire afin d’anesthésier les patients lors d’anesthésies générales. Cela a eu pour double effet d’augmenter la consommation d’anesthésiants dans les services de soin intensif et de créer une pénurie de ces produits limitant les interventions chirurgicales sous anesthésie générale (annulées ou reportées). <strong>Certains médecins de l’hôpital d’Arles se sont donc rapprochés très rapidement du studio de production <a href=\"https://www.vrtig0.fr\" target=\"_blank\" rel=\"noopener\" class=\"underline text-black visited:text-purple-600 hover-text-blue\" >Arlésien Vr'tig.0</a> pour envisager une solution complémentaire aux pratiques de l’hypnose au travers d’une application immersive de réalité virtuelle.</strong>"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ce rapprochement est né un partenariat solide et une application très performante : <strong>HYVIREA</strong>, testée sur des patients en bloc opératoire sous anesthésie loco-régionale. Avec l’accélération de la propagation du virus au début d’automne 2020, ce système opérationnel depuis plusieurs semaines à l’hôpital d’Arles, est un outil supplémentaire pour éviter les déprogrammations et reports d'interventions. Les services ambulatoires vont pouvoir être fluidifiés et nous attendons les supports renforcés de l’ARS (Agence régionale de la santé) PACA pour étendre ce dispositif à d’autres établissements de la région."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’utilisateur plonge dans un univers inconnu, à bord d’une barque traversant une grotte et se laissant porter lentement sur un lac qui aboutit par la suite sur une plage. Autour se trouve des hippocampes, champignons géants, cristaux luminescents et autres objets et créatures surprenantes."])}
  },
  "section3": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin d'enrichir l'application, l'équipe de développement travaille actuellement sur d'autres scénarios dans le but de diversifier son champ d'action afin de correspondre à un plus large public. Vous pouvez retrouver le teaser du dernier scénario en développement ci-dessous :"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouveaux scénarios en cours de développement !"])}
  },
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du rêve à la réalité"])}
}