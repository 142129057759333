/* eslint-disable */
<template>
  <body>
    <header class="header fixed z-50">
      <h1>
        <img
          src="../assets/logo_hyvirea_blanc.png"
          alt="Hyvirea - Made by VR'TIG.0"
          class="logo transition-all duration-700 ease-out"
        />
      </h1>
    </header>

    <main class="dark:bg-gray-900 bg-green-200 mt-35vh bg-header">
      <LocalSwitcher class="fixed bottom-1 left-1 z-30" />
      <h2 class="font-bold text-4xl p-2 font-slogan m-16 underline">
        {{ $t("slogan") }}
      </h2>
      <h3 class="text-2xl p-4 text-center">{{ $t("h3") }}</h3>
      <div class="lg:w-full p-4 lg:flex m-auto lg:justify-center items-center">
        <iframe
          src="https://www.youtube.com/embed/-ZUiF44gUGw"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="w-11/12 lg:w-5/12 lg:h-96 h-60 m-auto lg:m-8 md:h-96 opacity-0"
        ></iframe>
        <p
          class="m-auto my-4 lg:m-8 lg:w-5/12 opacity-0"
          v-html="$t('section1.text1')"
        ></p>
      </div>
      <div class="w-full m-auto py-8 flex flex-col">
        <p
          class="w-11/12 lg:w-10/12 m-auto opacity-0"
          v-html="$t('section1.text2')"
        ></p>
      </div>

      <div class="spacer_top"></div>

      <div
        class="
          lg:w-full
          p-4
          flex
          m-auto
          lg:justify-center
          items-center
          flex-col-reverse
          lg:flex-row
          bg-top
        "
      >
        <p
          class="my-4 m-auto lg:m-8 lg:w-5/12 opacity-0"
          v-html="$t('section2.text1')"
        ></p>
        <iframe
          src="https://www.youtube.com/embed/dN5GbeXA8Ww"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="w-11/12 lg:w-5/12 lg:h-96 h-60 m-auto lg:m-8 md:h-96 opacity-0"
        ></iframe>
      </div>
      <div
        class="
          lg:w-full
          p-4
          lg:flex
          m-auto
          lg:justify-center
          items-center
          bg-top
        "
      >
        <div
          class="
            my-4
            m-auto
            w-11/12
            lg:m-8 lg:w-5/12
            flex-wrap flex
            justify-center
          "
        >
          <img
            src="../assets/logo_hopital_arles.png"
            alt="Logo du centre hospitalier d'arles"
            class="m-4 opacity-0"
          />
          <img
            src="../assets/pms_arles.png"
            alt="logo du pôle médico-sportif d'arles"
            class="m-4 opacity-0"
          />
        </div>
        <p
          class="my-4 m-auto lg:m-8 lg:w-5/12 opacity-0"
          v-html="$t('section2.text2')"
        ></p>
      </div>
      <div
        class="
          lg:w-full
          p-4
          lg:flex
          m-auto
          lg:justify-center
          items-center
          bg-top
        "
      >
        <p
          class="my-4 m-auto lg:m-8 lg:w-5/12 opacity-0"
          v-html="$t('section2.text3')"
        ></p>
        <div
          class="
            my-4
            m-auto
            w-11/12
            lg:m-8 lg:w-5/12
            flex-wrap flex
            justify-center
          "
        >
          <img
            src="../assets/imgcompresse/plage.png"
            class="m-4 h-40 w-11/12 lg:w-5/12 opacity-0 object-cover"
            alt="photo d'un environnement dans l'application représentant une plage"
          />
          <img
            src="../assets/imgcompresse/salle01.png"
            class="m-4 h-40 w-11/12 lg:w-5/12 opacity-0 object-cover"
            alt="photo d'un environnement dans l'application réprésentant un lac dans une grotte"
          />
          <img
            src="../assets/imgcompresse/salle02.png"
            class="m-4 h-40 w-11/12 lg:w-5/12 opacity-0 object-cover"
            alt="photo d'un environnement dans l'application représentant un lac dans une grotte"
          />
          <img
            src="../assets/imgcompresse/salle03.png"
            class="m-4 h-40 w-11/12 lg:w-5/12 opacity-0 object-cover"
            alt="photo d'un environnement dans l'application"
          />
        </div>
      </div>

      <div class="spacer bg-top"></div>

      <div
        class="
          lg:w-full
          p-4
          flex flex-col
          m-auto
          lg:justify-center
          items-center
          bg-newscenar
        "
      >
        <h3 class="text-2xl p-4 text-center opacity-0 text-white">
          {{ $t("section3.title") }}
        </h3>
        <p class="my-4 m-auto lg:w-8/12 opacity-0 text-white">
          {{ $t("section3.text") }}
        </p>
        <iframe
          src="https://www.youtube.com/embed/O8KIbiTpXzY"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="w-11/12 lg:w-5/12 lg:h-96 h-60 m-auto lg:m-8 md:h-96 opacity-0"
        ></iframe>
      </div>

      <div class="spacer bg-top transform rotate-180 border-none"></div>
    </main>

    <footer
      class="bg-footer flex flex-col justify-center items-center relative"
    >
      <div
        class="
          w-full
          h-full
          flex flex-col
          justify-center
          items-center
          pt-8
          z-20
          text-white text-lg
        "
      >
        <h2 class="text-3xl text-shadow">Contact</h2>
        <address class="my-8 not-italic">
          <ul class="flex flex-col justify-around items-center mb-4">
            <li class="text-2xl text-shadow">VR'tig.0</li>
            <li class="group underline">
              <i class="fas fa-phone-alt group-hover:text-yellow-500 mr-2"></i>
              <a href="tel:0607884632" class="group-hover:text-yellow-500"
                >06 07 88 46 32</a
              >
            </li>
            <li class="group underline">
              <i class="fas fa-envelope group-hover:text-yellow-500 mr-2"></i>
              <a
                href="mailto:alain.chaix@vrtig0.fr"
                class="group-hover:text-yellow-500"
                >alain.chaix@vrtig0.fr</a
              >
            </li>
            <li class="group underline">
              <i
                class="fas fa-map-marker-alt group-hover:text-yellow-500 mr-2"
              ></i>
              <a
                href="https://www.google.fr/maps/place/3+Rue+Yvan+Audouard+B%C3%A2timent+E107,+13400+Arles/@43.6734367,4.636591,17zdata=!3m1!4b1!4m5!3m4!1s0x12b67673e66f2afb:0xb7da5c06ab985cb4!8m2!3d43.6734328!4d4.6387797"
                class="group-hover:text-yellow-500"
                target="_blank"
                rel="noopener"
                >3 rue Yvan Audouard Bâtiment E107 - 13520 Arles</a
              >
            </li>
          </ul>
        </address>
      </div>
    </footer>
  </body>
</template>

<script>
export default {
  components: {},
  name: "Home",
  methods: {
    headerEffect() {
      window.addEventListener("scroll", function () {
        const header = this.document.querySelector("header");
        header.classList.toggle("stick", window.scrollY > 40);
      });
    },
    scrollingEffect() {
      var elements;
      var windowHeight;

      function init() {
        elements = document.querySelectorAll(".opacity-0");
        windowHeight = window.innerHeight;
      }

      function checkPosition() {
        for (var i = 0; i < elements.length; i++) {
          var element = elements[i];
          var positionFromTop = elements[i].getBoundingClientRect().top;

          if (positionFromTop - windowHeight <= -180) {
            element.classList.add("transition_top");
            element.classList.remove("opacity-0");
          }
        }
      }
      window.addEventListener("scroll", checkPosition);
      window.addEventListener("scroll", init);
      init();
      checkPosition();
    },
  },
  beforeMount() {
    this.headerEffect();
  },
  mounted() {
    this.scrollingEffect();
  },
  beforeCreate() {
    this.$options.components.LocalSwitcher =
      require("./LocalSwitcher.vue").default;
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  background-image: url("../assets/header/header_desktop.png");
  background-repeat: repeat;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-position: center;
  transition: 0.7s ease-out;
  z-index: 1;
  @media (max-width: 768px) {
    background-image: url("../assets/header/header_mobile.png");
    background-repeat: repeat;
    background-position: left bottom;
  }
}
.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.7s ease-out;
  z-index: 1;
  width: 80%;
  max-width: 900px;
  max-height: 800px;
  @media (min-width: 425px) {
    width: 50%;
  }
}
.stick .logo {
  max-width: 300px;
  max-height: 140px;
  transition: all 0.7s ease-in-out;
}
.stick {
  height: 140px;
}
.mt-35vh {
  margin-top: 50vh;
}
.font-quicksand {
  font-family: "quicksand", Arial, Helvetica, sans-serif;
}
.font-slogan {
  font-family: "Bad script", Arial, Helvetica, sans-serif;
}
.transition_top {
  animation: transitiontopY 0.7s ease-out 0s both;
}
@keyframes transitiontopY {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
.bg-footer {
  background: url(../assets/footer/ExtraLarge.png);
  background-position: center;
  @media (max-width: 768px) {
    background: url(../assets/footer/Medium.png);
    background-position: center;
  }
}
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #4facf7;
  border-radius: 15px;
}
.spacer {
  aspect-ratio: 1460/360;
  background: url(../assets/spacer_newscenar.svg);
  fill-opacity: inherit;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: repeat-x;
}
.spacer_top {
  aspect-ratio: 1460/360;
  background: url(../assets/spacer_top.svg);
  fill-opacity: inherit;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: repeat-x;
}
.bg-newscenar {
  background-color: #c62368;
}
.bg-top {
  background-color: #81bff3;
}
html {
  background-color: #a7f3d0;
  background-image: url("../assets/subtle-prism.svg");
  background-attachment: fixed;
  background-size: cover;
}
.bg-header {
  background-color: #a7f3d0;
  background-image: url("../assets/subtle-prism.svg");
  background-attachment: fixed;
  background-size: cover;
}
</style>
