export default {
  "h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The VR application for therapeutic purposes"])},
  "section1": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To make people travel in the incredible world of virtual reality to accompany the patient during medical operations. Virtual reality, a therapeutic aid? VR (virtual reality) is not limited to entertainment. The field of possibilities of VR is enormous and is <strong>already being applied in the medical sector.</strong> Indeed, it can be a significant therapeutic support to accompany the patient. Whether for anesthesia, treatment or diagnosis, virtual reality has proven to be <strong>very effective in calming the patient during an operation.</strong> This technology in the health sector has proven itself, and the possibilities will change the medicine of tomorrow. Hyvirea is intended for surgical interventions in loco-regional anesthesia, it allows limiting the stress of the patient during the intervention."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this, the patient is immersed in a <strong>relaxing virtual world.</strong> In this way, during the operation, an anesthesiologist nurse will follow the patient's progress in the <strong>Hyvirea application</strong>. Moreover, he can guide him and set the parameters of the application according to the patient's wishes (desires, fears...). Hyvirea is a simple application, the patient is an observer of the environment. <strong>He is transported into a reassuring world that is completely different from the operating room in which he is physically located.</strong>"])}
  },
  "section2": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>The HYVIREA (Hypnosis Virtual Reality) project</strong> was initiated during the containment period following the COVID-19 pandemic and realized by the <a href=\"https://www.vrtig0.fr\" target=\"blank\" rel=\"noopener\" class=\"underline text-black visited:text-purple-600 hover-text-blue\" ><strong>Vr'tig.0 studio</strong></a>. Indeed, since the beginning of the containment period, French hospitals have received many suffering from this virus and have used a significant amount of curare in the resuscitation departments in order to put them into an artificial coma. The problem is that this molecule is also used in the operating room to anesthetize patients during general anesthesia. This has had the double effect of increasing the consumption of anesthetics in intensive care units and creating a shortage of these products, limiting surgical interventions under general anesthesia (cancelled or postponed). <strong>Some doctors of the hospital of Arles have therefore quickly approached the <a href=\"https://www.vrtig0.fr\" target=\"blank\" rel=\"noopener\" class=\"underline text-black visited:text-purple-600 hover-text-blue\" ><strong>Arles production studio Vr'tig.0</strong></a> to consider a complementary solution to the practice of hypnosis through an immersive virtual reality application.</strong>"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This partnership has resulted in a strong partnership and a very efficient application: <strong>HYVIREA</strong>, tested on patients in the operating room under loco-regional anesthesia. With the acceleration of the spread of the virus at the beginning of autumn 2020, this system, which has been operational for several weeks at the Arles hospital, is an additional tool to avoid deprogramming and postponing operations. Ambulatory services will be able to flow more smoothly, and we are waiting for reinforced support from the ARS (Regional Health Agency) PACA to extend this system to other establishments in the region."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user plunges into an unknown universe, aboard a boat crossing a cave and slowly being carried on a lake that ends up on a beach. Around them are seahorses, giant mushrooms, luminescent crystals and other surprising objects and creatures."])}
  },
  "section3": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to enrich the application, the development team is currently working on other scenarios in order to diversify its field of action to match a wider audience. You can find the teaser of the last scenario in development below :"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scenarios in development !"])}
  },
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From dream to reality"])}
}